<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Invoice
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/consolidated-payment-print-view/${consolidatedPayment.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        &nbsp;
        <v-btn
          small
          @click="save"
          class="relative"
          style="top:-4px">
            Save Changes
        </v-btn>
        <p class="pt-5">
          {{ consolidatedPayment.customerName }}
          <span v-if="consolidatedPayment.addressOne">
            <br />{{ consolidatedPayment.addressOne }}
          </span>
          <span v-if="consolidatedPayment.addressTwo">
            <br />{{ consolidatedPayment.addressTwo }}
          </span>
          <span v-if="consolidatedPayment.addressThree">
            <br />{{ consolidatedPayment.addressThree }}
          </span>
          <span v-if="consolidatedPayment.addressFour">
            <br />{{ consolidatedPayment.addressFour }}
          </span>
          <span v-if="consolidatedPayment.addressFive">
            <br />{{ consolidatedPayment.addressFive }}
          </span>
          <span v-if="consolidatedPayment.postcode">
            <br />{{ consolidatedPayment.postcode }}
          </span>
        </p>
      </v-col>
      <v-col class="col-6">
        <p>
          <v-checkbox label="Use VAT?" v-model="consolidatedPayment.vat"></v-checkbox>
          <v-text-field v-model="consolidatedPayment.reference" label="Reference"></v-text-field>
          <v-text-field v-model="consolidatedPayment.certificate_code" label="Certificate code"></v-text-field>
          <v-text-field v-model="consolidatedPayment.order_reference" label="Order Reference"></v-text-field>
          <v-text-field v-model="consolidatedPayment.account_reference" label="Account Reference"></v-text-field>
          <v-textarea rows="2" v-model="consolidatedPayment.notes" label="Notes"></v-textarea>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedDatefns"
                clearable
                label="Date"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="consolidatedPayment.date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="consolidatedPayment.date"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="60%" class="text-left">Description</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Rate</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in consolidatedPayment.consolidatedPaymentLines" :key="index">
                <td width="60%">
                  <v-text-field v-model="line.description"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="line.quantity"
                    type="number"
                    min="0"
                    step="1"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="line.pounds"
                    type="number"
                    min="0"
                    step="0.01"></v-text-field>
                </td>
                <td>
                  {{ (line.quantity * line.pounds) | currency }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pl-5 pr-6">
        <p>
          Sub-total:
          <span class="float-right">
            {{ subTotal | priceInPounds | currency }}
          </span>
        </p>
        <p>
          VAT:
          <span class="float-right">
            {{ vat | priceInPounds | currency }}
          </span>
        </p>
        <p>
          Total:
          <span class="float-right">
            {{ (subTotal + vat) | priceInPounds | currency }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
 import { format, parseISO } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'InvoicesDraftConsolidatedPayment',
  data() {
    return {
      consolidatedPayment: {
        account_reference: '',
        addressOne: '',
        addressTwo: '',
        addressThree: '',
        addressFour: '',
        addressFive: '',
        
        consolidatedPaymentLines: [
          {
            description: '',
            id: '',
            pounds: 0.00,
            quantity: 0,
            value: 0,
            vat_value: 0,
          },
        ],
        customerName: '',
        date: '',
        id: 0,
        order_reference: '',
        postcode: '',
        reference: '',
        vat: true,
      },
      menu: false,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      subTotal: 0,
      vat: 0,
      message: '',
      showMessage: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    computedDateFormattedDatefns () {
        return this.consolidatedPayment.date ? format(parseISO(this.consolidatedPayment.date), 'EEEE, MMMM do yyyy') : ''
      },
  },
  watch: {
    startDate () {
      this.consolidatedPayment.date = this.formatDate(this.startDate);
    },
    consolidatedPayment: {
      deep: true,
      handler() {
        let subtotal = 0;
        for ( let i = 0; this.consolidatedPayment.consolidatedPaymentLines.length > i; i += 1) {
          const lineTotal = this.consolidatedPayment.consolidatedPaymentLines[i].quantity * this.consolidatedPayment.consolidatedPaymentLines[i].pounds;
          subtotal += lineTotal;
        }
        this.subTotal = (subtotal * 100);
        if (this.consolidatedPayment.vat === true) {
          this.vat = ((this.subTotal * 1.2) - this.subTotal);
        } else {
          this.vat = 0;
        }
      },
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    save() {
      axios.post(`/consolidatedPayments/saveUpdates.json?token=${this.token}`, this.consolidatedPayment)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
        });
    },
    getConsolidatedPayment() {
      axios.get(`/consolidatedPayments/get/${this.$route.params.id}.json?token=${this.token}`)
        .then((response) => {
          this.consolidatedPayment = response.data.consolidatedPayment;
        });
    },
  },
  mounted() {
    this.getConsolidatedPayment();
  },
};
</script>
